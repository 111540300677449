.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rotate {
  padding: 15px !important;
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}